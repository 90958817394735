import React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import cn from 'classnames';
import cogoToast from 'cogo-toast';

import { loginAction } from '../actions/authActions';
import { login } from '../services/authService';

import logo from '../img/logo.svg';

const schema = {
  email: {
    required: 'Укажите ваш email',
    pattern: {
      value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      message: 'Неправильный формат email',
    },
  },
  password: {
    required: 'Укажите ваш пароль',
    maxLength: {
      value: 10,
      message: 'Пароль может быть не более 10 символов',
    },
    pattern: {
      value: /^[A-Za-z0-9]+$/,
      message:
        'Недопустимый символ, используйте только цифры и буквы латинского алфавита',
    },
  },
};

const LoginForm = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { register, handleSubmit, errors } = useForm();

  const onLoginFormSubmit = async (data) => {
    const [err, res] = await login(data);

    if (res.data.error) {
      cogoToast.error(res.data.message, { position: 'top-right' });
      return;
    }

    if (!res.data.data) {
      cogoToast.error(
        'На сайте ведутся технические работы! Пожалуйста, обновите страничку спустя одну минуту.',
        { position: 'top-right' }
      );
      return;
    }

    const { sessionId, client, message } = res.data.data;

    const userInfo = {
      sessionId,
      name: client.fullName,
      email: client.email,
    };

    dispatch(loginAction(userInfo));
    history.push('/');
  };

  return (
    <form
      onSubmit={handleSubmit(onLoginFormSubmit)}
      className="form w-full max-w-lg"
      noValidate
    >
      <header className="login-header">
        <p className="logo text-3xl">
          ОСАГО
          {/* <img
            className="mx-auto"
            src={logo}
            alt="OsagoTop.ru"
            width="190"
            height="44"
          /> */}
        </p>
        <p className="font-medium">Вход в Личный кабинет</p>
      </header>

      <div className="login-field mb-8">
        <label htmlFor="email" className="form-label">
          Электронная почта
        </label>
        <input
          id="email"
          className={cn('input', { 'input--danger': errors.email })}
          type="email"
          name="email"
          placeholder="Ваш email"
          ref={register(schema.email)}
        />
        <p className="helper helper--danger mt-1">
          {errors.email && errors.email.message}
        </p>
      </div>

      <div className="login-field mb-8">
        <label htmlFor="password" className="form-label">
          Пароль
        </label>
        <input
          id="password"
          className={cn('input', { 'input--danger': errors.password })}
          type="password"
          name="password"
          placeholder="Ваш пароль"
          maxLength="10"
          ref={register(schema.password)}
        />
        <p className="helper helper--danger mt-1">
          {errors.password && errors.password.message}
        </p>
      </div>
      <div className="flex flex-col sm:flex-row items-center justify-between">
        <button
          className="btn btn--primary w-full sm:w-auto mb-2 sm:mb-0 py-3"
          type="submit"
        >
          Войти
        </button>
        <Link
          to="/passreset"
          className="text-sm text-royalblue font-semibold hover:underline"
        >
          Восстановить пароль
        </Link>
      </div>
    </form>
  );
};

export default LoginForm;
